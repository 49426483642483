import React from 'react';
import FbIcon from '../../static/assets/facebook-icon.svg';
import IgIcon from '../../static/assets/instagram-icon.svg';
import LinkedIn from '../../static/assets/linkedin-in-icon.svg';

const Footer = props => {
  return (
    <div className="footer-landingPage-container">
      <div className="">
        <div className="full-container">
          <div className="footer-landingPage-container-elements">
            <div className="footer-landingPage-container-icons">
              <a
                href="https://www.facebook.com/agreedsoftware"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="fb"
              >
                <i>
                  <FbIcon className="iconsFB" />
                </i>
              </a>
              <a
                href="https://www.instagram.com/agreedsoftware/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="ig"
              >
                <i>
                  <IgIcon className="icons" />
                </i>
              </a>
              <a
                href="https://www.linkedin.com/company/42273938/admin/"
                target="_blank"
                aria-label="linkedln"
                rel="noopener noreferrer"
              >
                <i>
                  <LinkedIn className="icons" />
                </i>
              </a>
            </div>
            <div className="footer-landingPage-container-copyright">
              <h2 className="footer-landingPage-container-copyright-text">
                © Agreed 2023
                <a
                  href="https://app.agreed.io/legal/terms-of-use/data-protection"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-landingPage-container_link"
                  aria-label="security"
                >
                  {' '}
                  | Security{' '}
                </a>{' '}
                <a
                  href="https://app.agreed.io/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-landingPage-container_link"
                  aria-label="privacy"
                >
                  | Privacy Policy
                </a>{' '}
                <a
                  href="https://app.agreed.io/legal/terms-of-use/service"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-landingPage-container_link"
                  aria-label="teams"
                >
                  | Terms of Use
                </a>{' '}
              </h2>
            </div>
            <div className="footer-landingPage-container_maintained">
              <p className="footer-landingPage-container_maintained-text">
                Created by
                <a
                  href="/"
                  rel="noopener noreferrer"
                  className="footer-landingPage-container_maintained-link"
                  aria-label="agreed"
                >
                  Agreed
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
