
import React,{useRef} from "react";
import Header from '../../src/components/headerComponent';
import BusinessDeveloperHome from "../components/landingComponents/businessDeveloperHome";
import { graphql } from 'gatsby';
import BusinessDeveloperBrakePoint from "../components/landingComponents/businessDeveloperBrakePoint";
import HigherEducationAdministrationComponet from "../components/landingComponents/higherEducationAdministrationComponent";
import BusinessDeveloperFree from "../components/landingComponents/businessDeveloperFree";
import OfferBrakePointComponent from '../components/offerCompoents/offerBrakepointComponent';
import ExplanationComponentTwo from '../components/landingComponents/explanationComponentTwo';
import ExplanationButtonAgree from '../../static/assets/ExplanationButtonAgree.svg';
import ExplanationButtonDraft from '../../static/assets/ExplanationButtonDraft.svg';
import ExplanationButtonPitch from '../../static/assets/ExplanationButtonPitch.svg';
import ExplanationButtonPlan from '../../static/assets/ExplanationButtonPlan.svg';
import Mission from '../../static/assets/mission.svg';
import Checkmark from '../../static/assets/checkmark.svg';
import ReviewsCoponent from '../components/landingComponents/reviewsComponent.js';
import RequestDemoComponent from '../components/landingComponents/requestDemoComponent.js';
import Footer from '../components/footerComponent.js';
import Layout from '../components/layout';
const buttonItems = [
  <ExplanationButtonAgree className="explanationButtonIcon leader-button-icone" />,
  <ExplanationButtonDraft className="explanationButtonIcon leader-button-icone" />,
  <ExplanationButtonPitch className="explanationButtonIcon leader-button-icone" />,
  <ExplanationButtonPlan className="explanationButtonIcon leader-button-icone" />,
  <Checkmark className="explanationButtonIcon leader-button-icone" />,
  <Mission className="explanationButtonIcon leader-button-icone" />,
];
const BusinessDeveloper = (props) =>{
     const {
       data: {
         wpPage: {
           seo,
           businessDeveloperHome: {
             businessDeveloperHomeTitle,
             businessDeveloperHomeImage: {
               localFile: {
                 childImageSharp: {
                   gatsbyImageData: businessDeveloperHomeImage,
                 },
               },
             },
           },
           higherEducationProffesors: {
             higherEducationProffesorsDescription,
             higherEducationProffesorsSubtitle,
             higherEducationProffesorsTitle,
             higherEducationProffesorsImage: {
               localFile: {
                 childrenImageSharp: [
                   { gatsbyImageData: higherEducationProffesorsImage },
                 ],
               },
             },
           },
           higherEducationStudentsSection: {
             higherEducationStudentsSubtitle,
             higherEducationStudentsTitle,
             higherEducationStudentsDescription,
             higherEducationStudentsImage: {
               localFile: {
                 childrenImageSharp: [
                   { gatsbyImageData: higherEducationStudentsImage },
                 ],
               },
             },
           },

           higherEducationAdministrationSection: {
             higherEducationAdministrationButton,
             higherEducationAdministrationDescription,
             higherEducationAdministrationImage: {
               localFile: {
                 childrenImageSharp: [
                   { gatsbyImageData: higherEducationAdministrationImage },
                 ],
               },
             },
             higherEducationAdministrationSubtitle,
             higherEducationAdministrationTitle,
           },
           businessDeveloperFree: {
             businessDeveloperFreeButton,
             businessDeveloperFreeDescription,
             businessDeveloperFreeTitle,
             businessDeveloperFreeImage: {
               localFile: {
                 childImageSharp: {
                   gatsbyImageData: businessDeveloperFreeImage,
                 },
               },
             },
           },
           offerBrakePointSection: {
             bankOfAmericaImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: bankAmericaImageOf }],
               },
             },
             guthyImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: guthyImage }],
               },
             },
             humanaImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: humanaImage }],
               },
             },
             mellonImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: mellonImage }],
               },
             },
             mrvImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: mrvImage }],
               },
             },
             mufgImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: mufgImage }],
               },
             },
             nyseImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: nyseImage }],
               },
             },
             panasonicImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: panasonicImage }],
               },
             },
             pncImage: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: pncImage }],
               },
             },
             pairwiesLogo: {
               localFile: {
                 childrenImageSharp: [{ gatsbyImageData: pairwiesLogo }],
               },
             },
           },
           landingPageExplanationSection: {
             landingApprovalIdea,
             landingApprovalIdeaDescription,
             landingApprovalIdeaIamge: {
               localFile: {
                 childImageSharp: { gatsbyImageData: landingApprovalIdeaIamge },
               },
             },
             landingButtonText,
             landingDeliverIdea,
             landingDeliverIdeaDescription,
             landingDeliverIdeaImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: landingDeliverIdeaImage },
               },
             },
             landingGoodIdeaDescription,
             landingGoodIdeaImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: landingGoodIdeaImage },
               },
             },
             landingGoodIdeaTitle,
             landingInProgressIdeaDescription,
             landingInProgressIdeaImage: {
               localFile: {
                 childImageSharp: {
                   gatsbyImageData: landingInProgressIdeaImage,
                 },
               },
             },
             landingInProgressIdeaTitle,
             landingRealiseIdeaTitle,
             landingRightIdeaDescription,
             landingRightIdeaImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: landingRightIdeaImage },
               },
             },
             landingRightIdeaTitle,
             landingThinkIdeDescription,
             landingThinkIdeaImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: landingThinkIdeaImage },
               },
             },
             landingThinkIdeaTitle,
           },
           landingPageReviewsSection: {
             landingReviewGreg,
             landingReviewsErin,
             landingReviewsSteffen,
             reviewsNameSliderOne,
             reviewsNameSliderThree,
             reviewsNameSliderTwo,
             reviewsPositionSliderOne,
             reviewsPositionSliderThree,
             reviewsPositionSliderTwo,
           },
           requestDemoSection: {
             requestDemoSectionButton,
             requestDemoSectionDescription,
             requestDemoSectionTitle,
             requestDemoSectionImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: requestDemoSectionImage },
               },
             },
           },
         },
       },
       pageContext: { title },
     } = props;
       const learnMoreRef = useRef(null);
       const onLearnMoreClick = () => {
         console.log(learnMoreRef.current.scrollTo());
         if (learnMoreRef && learnMoreRef.current) {
           learnMoreRef.current.scrollIntoView();
         }
       };
    return (
      <Layout title={title} seo={seo}>
        <Header />
        <BusinessDeveloperHome
          businessTitle={businessDeveloperHomeTitle}
          businessDeveloperImage={businessDeveloperHomeImage}
        />
        <BusinessDeveloperBrakePoint />
        <HigherEducationAdministrationComponet
          heigherEducationSubtitle={higherEducationAdministrationSubtitle}
          heigherEducationTitle={higherEducationAdministrationTitle}
          heigherEducationDescription={higherEducationAdministrationDescription}
          heigherEducationButtonText={higherEducationAdministrationButton}
          administrationImage={higherEducationAdministrationImage}
          isBusinessDeveloper={true}
          onLearnMoreClick={onLearnMoreClick}
        />
        <HigherEducationAdministrationComponet
          // isProffesors={true}
          heigherEducationSubtitle={higherEducationProffesorsSubtitle}
          heigherEducationTitle={higherEducationProffesorsTitle}
          heigherEducationDescription={higherEducationProffesorsDescription}
          heigherEducationButtonText={higherEducationAdministrationButton}
          administrationImage={higherEducationProffesorsImage}
          isBusinessDeveloper={true}
          onLearnMoreClick={onLearnMoreClick}
        />
        <HigherEducationAdministrationComponet
          heigherEducationSubtitle={higherEducationStudentsSubtitle}
          heigherEducationTitle={higherEducationStudentsTitle}
          heigherEducationDescription={higherEducationStudentsDescription}
          heigherEducationButtonText={higherEducationAdministrationButton}
          administrationImage={higherEducationStudentsImage}
          isBusinessDeveloper={true}
          onLearnMoreClick={onLearnMoreClick}
        />
        <BusinessDeveloperFree
          businessDeveloperFreeTitle={businessDeveloperFreeTitle}
          businessDeveloperFreeDescription={businessDeveloperFreeDescription}
          businessDeveloperFreeImage={businessDeveloperFreeImage}
          businessDeveloperFreeButton={businessDeveloperFreeButton}
        />
        <OfferBrakePointComponent
          bankOfAmericaImage={bankAmericaImageOf}
          guthyImage={guthyImage}
          humanImage={humanaImage}
          mellonImage={mellonImage}
          MRVImage={mrvImage}
          mufgImage={mufgImage}
          nyseImage={nyseImage}
          panasonicImage={panasonicImage}
          pncImage={pncImage}
          isBusnessDeveloper={true}
          newLogo={pairwiesLogo}
        />
        <ExplanationComponentTwo
          buttonItems={buttonItems}
          explanationThinkIdeaTitle={landingThinkIdeaTitle}
          explanationThinkIdeaDescription={landingThinkIdeDescription}
          explanationButtonTitle={landingButtonText}
          explanationRightIdeaTitle={landingRightIdeaTitle}
          explanationRightIdeaDescription={landingRightIdeaDescription}
          explanationGoodIdeaTitle={landingGoodIdeaTitle}
          explanationGoodIdeaDescription={landingGoodIdeaDescription}
          explanationProgressIdeaTitle={landingInProgressIdeaTitle}
          explanationProgressIdeaDescription={landingInProgressIdeaDescription}
          explanationRealiseIdeaTitle={landingRealiseIdeaTitle}
          ExplanationButtonOne={landingThinkIdeaImage}
          ExplanationButtonTwo={landingRightIdeaImage}
          ExplanationButtonThree={landingGoodIdeaImage}
          ExplanationButtonFore={landingInProgressIdeaImage}
          explanationApprovalIdeaTitle={landingApprovalIdea}
          explanationApprovalIdeaDescription={landingApprovalIdeaDescription}
          explanationDeliverIdeaText={landingDeliverIdea}
          explanationDeliverIdeaDescription={landingDeliverIdeaDescription}
          ExplanationButtonEight={landingApprovalIdeaIamge}
          ExplanationButtonSix={landingDeliverIdeaImage}
        />
        <ReviewsCoponent
          isHigherEducation={true}
          reviewsTitle="Changemakers love Agreed"
          reviewsSeffenDescription={landingReviewsSteffen}
          reviewsSliderOneName={reviewsNameSliderOne}
          reviewsSliderOnePosition={reviewsPositionSliderOne}
          reviewsErinDescription={landingReviewsErin}
          reviesSliderTwoName={reviewsNameSliderTwo}
          reviewsSliderTwoPosition={reviewsPositionSliderTwo}
          reviewsSectionGregDescription={landingReviewGreg}
          reviewsSliderThreeName={reviewsNameSliderThree}
          reviewSliderThreePosition={reviewsPositionSliderThree}
        />
        <RequestDemoComponent
          requestDemoTitle={requestDemoSectionTitle}
          requestDemoDescription={requestDemoSectionDescription}
          requestDemoButton={requestDemoSectionButton}
          requestDemoImage={requestDemoSectionImage}
          isBusnessDeveloper={true}
          learnMoreRef={learnMoreRef}
        />
        <Footer />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPage(slug: { eq: "business-developer" }) {
      seo {
        metaDesc
        title
      }
      businessDeveloperHome {
        businessDeveloperHomeTitle
        businessDeveloperHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      higherEducationAdministrationSection {
        higherEducationAdministrationButton
        higherEducationAdministrationDescription
        higherEducationAdministrationSubtitle
        higherEducationAdministrationTitle
        higherEducationAdministrationImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      higherEducationHomeSection {
        higherEducationHomeTitle
        higherEducationHomeSubtitle
        higherEducationHomeImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      higherEducationProffesors {
        higherEducationProffesorsTitle
        higherEducationProffesorsSubtitle
        higherEducationProffesorsDescription
        higherEducationProffesorsImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      higherEducationStudentsSection {
        higherEducationStudentsTitle
        higherEducationStudentsSubtitle
        higherEducationStudentsDescription
        higherEducationStudentsImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      businessDeveloperFree {
        businessDeveloperFreeButton
        businessDeveloperFreeDescription
        businessDeveloperFreeTitle
        businessDeveloperFreeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      offerBrakePointSection {
        bankOfAmericaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        pairwiesLogo {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        guthyImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        humanaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mellonImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mrvImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mufgImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        nyseImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        panasonicImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        pncImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
      }
      landingPageReviewsSection {
        landingReviewGreg
        landingReviewsErin
        landingReviewsSteffen
        landingReviewsTitle
        reviewsNameSliderOne
        reviewsNameSliderThree
        reviewsNameSliderTwo
        reviewsPositionSliderOne
        reviewsPositionSliderThree
        reviewsPositionSliderTwo
      }
      landingPageExplanationSection {
        landingApprovalIdea
        landingApprovalIdeaDescription
        landingApprovalIdeaIamge {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingButtonText
        landingDeliverIdea
        landingDeliverIdeaDescription
        landingDeliverIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingGoodIdeaDescription
        landingGoodIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingGoodIdeaTitle
        landingInProgressIdeaDescription
        landingInProgressIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingInProgressIdeaTitle
        landingRealiseIdeaDescription
        landingRealiseIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingRealiseIdeaTitle
        landingRightIdeaDescription
        landingRightIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingRightIdeaTitle
        landingThinkIdeDescription
        landingThinkIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingThinkIdeaTitle
      }
      landingPageReviewsSection {
        landingReviewGreg
        landingReviewsErin
        landingReviewsSteffen
        landingReviewsTitle
        reviewsNameSliderOne
        reviewsNameSliderThree
        reviewsNameSliderTwo
        reviewsPositionSliderOne
        reviewsPositionSliderThree
        reviewsPositionSliderTwo
      }
      requestDemoSection {
        requestDemoSectionButton
        requestDemoSectionDescription
        requestDemoSectionTitle
        requestDemoSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default BusinessDeveloper;