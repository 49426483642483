import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
const ExplanationComponentTwo = props => {
  const { buttonItems } = props;
  const {
    explanationThinkIdeaTitle,
    explanationThinkIdeaDescription,
    explanationButtonTitle,
    explanationRightIdeaTitle,
    explanationRightIdeaDescription,
    explanationGoodIdeaTitle,
    explanationGoodIdeaDescription,
    explanationProgressIdeaTitle,
    explanationProgressIdeaDescription,
    ExplanationButtonOne,
    ExplanationButtonTwo,
    ExplanationButtonThree,
    ExplanationButtonFore,
    ExplanationButtonSix,
    explanationApprovalIdeaTitle,
    explanationApprovalIdeaDescription,
    explanationDeliverIdeaText,
    explanationDeliverIdeaDescription,
    ExplanationButtonEight,
  } = props;
  const [isActive, setisActive] = useState(0);

  return (
    <div className="explanation-section">
      <div className="full-container">
        <div className="explanation-section_nav ">
          <ul className="explanation-section_nav-list">
            {buttonItems &&
              buttonItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${
                      index === isActive
                        ? 'explanation-section_activeListItem'
                        : 'explanation-section_nav-list-item'
                    }`}
                  >
                    <button
                      aria-label="list item"
                      onClick={() => {
                        setisActive(index);
                      }}
                      className={`${
                        index === isActive
                          ? 'explanation-section_activeButtonList selected leader-button-active'
                          : 'explanation-section_nav-list-button leader-button'
                      }`}
                    >
                      {item}
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        {isActive === 0 && (
          <div className="explanation-section_container-leader">
            <div className="explanation-section_description-section">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 ">
                  <GatsbyImage
                    imgStyle={{ width: '100%' }}
                    image={ExplanationButtonOne}
                    alt={''}
                    className="explanation-section_image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="explanation-section_description-text">
                    <h2 className="explanation-section_title">
                      {explanationThinkIdeaTitle}
                      <span className="explanation-span"></span>
                    </h2>
                    <div className="explanation-section_description">
                      <p>{explanationThinkIdeaDescription}​ ​ ​ ​</p>
                    </div>
                    <div className="teamwork-section_form-wrapper">
                      <Link
                      to='/contact'
                        type="submit"
                        className="teamwork-section_form-button"
                      >
                        {explanationButtonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isActive === 1 && (
          <div className="explanation-section_container-leader">
            <div className="explanation-section_description-section">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 ">
                  <GatsbyImage
                    imgStyle={{ width: '100%' }}
                    image={ExplanationButtonTwo}
                    alt={''}
                    className="explanation-section_image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="explanation-section_description-text">
                    <h2 className="explanation-section_title">
                      {explanationRightIdeaTitle}
                      <span className="explanation-span"></span>
                    </h2>
                    <div className="explanation-section_description">
                      <p>{explanationRightIdeaDescription}​ ​</p>
                    </div>
                    <div className="teamwork-section_form-wrapper">
                      <Link
                      to='/contact'
                        type="submit"
                        className="teamwork-section_form-button"
                      >
                        {explanationButtonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isActive === 2 && (
          <div className="explanation-section_container-leader">
            <div className="explanation-section_description-section">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 ">
                  <GatsbyImage
                    imgStyle={{ width: '100%' }}
                    image={ExplanationButtonThree}
                    alt={''}
                    className="explanation-section_image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="explanation-section_description-text">
                    <h2 className="explanation-section_title">
                      {explanationGoodIdeaTitle}
                      <span className="explanation-span"></span>
                    </h2>
                    <div className="explanation-section_description">
                      <p>{explanationGoodIdeaDescription}​​ ​ ​</p>
                    </div>
                    <div className="teamwork-section_form-wrapper">
                      <Link
                      to='/contact'
                        type="submit"
                        className="teamwork-section_form-button"
                      >
                        {explanationButtonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isActive === 3 && (
          <div className="explanation-section_container-leader">
            <div className="explanation-section_description-section">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12  ">
                  <GatsbyImage
                    imgStyle={{ width: '100%' }}
                    image={ExplanationButtonFore}
                    alt={''}
                    className="explanation-section_image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="explanation-section_description-text">
                    <h2 className="explanation-section_title">
                      {explanationProgressIdeaTitle}
                      <span className="explanation-span"></span>
                    </h2>
                    <div className="explanation-section_description">
                      <p>{explanationProgressIdeaDescription}​</p>
                    </div>
                    <div className="teamwork-section_form-wrapper">
                      <Link 
                      to='/contact'
                        type="submit"
                        className="teamwork-section_form-button"
                      >
                        {explanationButtonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isActive === 4 && (
          <div className="explanation-section_container-leader">
            <div className="explanation-section_description-section">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12  ">
                  <GatsbyImage
                    imgStyle={{ width: '100%' }}
                    image={ExplanationButtonEight}
                    alt={''}
                    className="explanation-section_image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="explanation-section_description-text">
                    <h2 className="explanation-section_title">
                      {explanationApprovalIdeaTitle}
                      <span className="explanation-span"></span>
                    </h2>
                    <div className="explanation-section_description">
                      <p>{explanationApprovalIdeaDescription}​</p>
                    </div>
                    <div className="teamwork-section_form-wrapper">
                      <Link
                      to='/contact'
                        type="submit"
                        className="teamwork-section_form-button"
                      >
                        {explanationButtonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isActive === 5 && (
          <div className="explanation-section_container-leader">
            <div className="explanation-section_description-section">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12  ">
                  <GatsbyImage
                    imgStyle={{ width: '100%' }}
                    image={ExplanationButtonSix}
                    alt={''}
                    className="explanation-section_image"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="explanation-section_description-text">
                    <h2 className="explanation-section_title">
                      {explanationDeliverIdeaText}
                      <span className="explanation-span"></span>
                    </h2>
                    <div className="explanation-section_description">
                      <p>
                        {explanationDeliverIdeaDescription} ​ ​ ​ ​ ​ ​​ ​ ​ ​
                        ​​ ​
                      </p>
                    </div>
                    <div className="teamwork-section_form-wrapper">
                      <Link
                      to='/contact'
                        type="submit"
                        className="teamwork-section_form-button"
                      >
                        {explanationButtonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ExplanationComponentTwo;
