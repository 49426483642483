import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { GatsbyImage } from 'gatsby-plugin-image';


SwiperCore.use([Autoplay]);
const OfferBrakePointComponent = props => {
  const {
    panasonicImage,
    humanImage,
    bankOfAmericaImage,
    mellonImage,
    nyseImage,
    pncImage,
    mufgImage,
    guthyImage,
    MRVImage,
    isBusnessDeveloper,
    newLogo,
  } = props;
  return (
    <div className="full">
      <div className="row">
        <div className="col-12">
          <div
            className={`brakePoint-section ${
              isBusnessDeveloper ? 'businessDeveloper-brakePointOffer' : ''
            }`}
          >
            <div className=" brakePoint-section_image"></div>

            <div className=" brakePoint-section-text">
              <div className="sucess-section_swiper brakePoint-section_swiper-section">
                <Swiper
                  className="brakePoint-section-swiper"
                  spaceBetween={20}
                  slidesPerView={1}
                  autoplay
                  loop
                  breakpoints={{
                    1200: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    425: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={panasonicImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={humanImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={bankOfAmericaImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={mellonImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={mufgImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={nyseImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={pncImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={guthyImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={MRVImage}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <GatsbyImage
                      imgStyle={{ width: '100%' }}
                      image={newLogo}
                      alt={''}
                      className="sucess-section_swiper-image  brakePoint-section-swiper-image"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OfferBrakePointComponent;
