import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import HeigherEducationAdministartionButtonIcon from '../../../static/assets/buttonIcone.svg';
const HigherEducationAdministrationComponet = (props) =>{
    const {
      heigherEducationSubtitle,
      heigherEducationTitle,
      heigherEducationDescription,
      heigherEducationButtonText,
      administrationImage,
      isProffesors,
      isStudents,
      higherEduationLink,
      isBusinessDeveloper,
      isHigher,
      onLearnMoreClick,
    } = props;
    return (
      <div className="full-container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 order-md-1 order-2 higher-eduacation-adminstartion-section-col">
            <div className="higher-eduacation-adminstartion-section">
              <div className="higher-eduacation-adminstartion-section-text ">
                <div
                  className={`higher-eduacation-adminstartion-section-text-border ${
                    isProffesors ? 'proffesors-border' : ''
                  } ${isStudents ? 'students-border' : ''} ${
                    isBusinessDeveloper ? 'businessDeveloper' : ''
                  }`}
                ></div>
                <p
                  className={`higher-eduacation-adminstartion-section-text-description ${
                    isProffesors ? 'proffesors' : ''
                  } ${isStudents ? 'students' : ''}`}
                >
                  {heigherEducationSubtitle}
                </p>
              </div>
              <h2 className="higher-eduacation-adminstartion-section-title">
                {heigherEducationTitle}
                <span
                  className={`higher-eduacation-adminstartion-section-title-span ${
                    isProffesors ? 'proffesors-title-span' : ''
                  } ${isStudents ? 'students-title-span' : ''} ${
                    isBusinessDeveloper ? 'businessDeveloper' : ''
                  }`}
                ></span>
              </h2>
              <p className="higher-eduacation-adminstartion-section-subtitle">
                {heigherEducationDescription}
              </p>
              {isHigher && (
                <Link
                  className={`higher-eduacation-adminstartion-section-button ${
                    isProffesors ? 'proffesors-button' : ''
                  } ${isStudents ? 'students-button' : ''} ${
                    isBusinessDeveloper ? 'businessDeveloper-button' : ''
                  }`}
                  to={higherEduationLink}
                >
                  {heigherEducationButtonText}{' '}
                  <HeigherEducationAdministartionButtonIcon />
                </Link>
              )}
              {isBusinessDeveloper && (
                <button
                  aria-label="education button"
                  className={`higher-eduacation-adminstartion-section-button ${
                    isProffesors ? 'proffesors-button' : ''
                  } ${isStudents ? 'students-button' : ''} ${
                    isBusinessDeveloper ? 'businessDeveloper-button' : ''
                  }`}
                  onClick={onLearnMoreClick}
                >
                  {heigherEducationButtonText}
                  <HeigherEducationAdministartionButtonIcon />
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 order-md-2 order-1">
            <GatsbyImage
              alt={''}
              image={administrationImage}
              className="higher-eduacation-adminstartion-section-image"
            />
            {/* <img
              src={administrationImage}
              className="higher-eduacation-adminstartion-section-image"
            /> */}
          </div>
        </div>
      </div>
    );
}
export default HigherEducationAdministrationComponet;