import { GatsbyImage } from "gatsby-plugin-image";
import BusinessFreeIconButton from '../../../static/assets/businessFreeButtonIcon.svg';
import React from "react";

const BusinessDeveloperFree = (props) =>{
    const {
      businessDeveloperFreeTitle,
      businessDeveloperFreeDescription,
      businessDeveloperFreeImage,
      businessDeveloperFreeButton,
    } = props;
     const handleLangSwitch = () => {
       window.location.href = 'https://app.agreed.io/signup';
     };
    return (
      <div className="business-free-section">
        <div className="row">
          <div className="col-md-6 business-free-section-col">
            <div className="business-free-section-text">
              <h3 className="business-free-section-text-title">
                {businessDeveloperFreeTitle}
              </h3>
              <p className="business-free-section-text-description">
                {businessDeveloperFreeDescription}
              </p>
              <button
                aria-label="business free"
                className="business-free-section-button"
                onClick={handleLangSwitch}
                onKeyDown={handleLangSwitch}
              >
                {businessDeveloperFreeButton}
                <BusinessFreeIconButton />
              </button>
            </div>
          </div>
          <div className="col-md-6 business-free-section-col">
            <GatsbyImage
              image={businessDeveloperFreeImage}
              style={{ width: '100%' }}
              alt={''}
            />
          </div>
        </div>
      </div>
    );
}
export default BusinessDeveloperFree