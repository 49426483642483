import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import CaroselBackground from '../../images/caroselBackground.png';
import SteveJobs from '../../../static/assets/steffen.svg';
import ThomasEdison from '../../../static/assets/erin.svg';
import PeterDrucker from '../../../static/assets/greg.svg';
import { Link } from 'gatsby';

const ReviewsCoponent = props => {
  const {
    reviewsTitle,
    reviewsSeffenDescription,
    reviewsSliderOneName,
    reviewsSliderOnePosition,
    reviewsErinDescription,
    reviesSliderTwoName,
    reviewsSliderTwoPosition,
    reviewsSectionGregDescription,
    reviewsSliderThreeName,
    reviewSliderThreePosition,
  } = props;
  return (
    <div className="full-container">
      <div className="row">
        <div className="col-12">
          <div className="riviews-section">
            <div className=" riviews-section_header">
              <h2 className=" riviews-section_header-text">{reviewsTitle}</h2>
            </div>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={10}
              slidesPerView={1}
              navigation
              centeredSlides
              loop
            >
              <SwiperSlide>
                <div className="riviews-section_carosel">
                  <img
                    src={CaroselBackground}
                    alt="CaroselBackground"
                    className="riviews-section_carosel-image"
                  />
                  <div className="riviews-section_rectangle-text">
                    <p className="riviews-section_rectangle-description">
                      {reviewsSeffenDescription}
                    </p>
                  </div>
                </div>
                <div className="riviews-section_people">
                  <Link
                    to="https://www.linkedin.com/in/steffen-mckernan-37957b6/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="riviews-section_people-wrapper">
                      <div className="riviews-section_people-icon">
                        <SteveJobs className="riviews-section_people-icon-face" />
                      </div>
                    </div>
                  </Link>
                  <p className="riviews-section_people-name">
                    {reviewsSliderOneName}
                  </p>
                  <p className="riviews-section_people-description">
                    {reviewsSliderOnePosition}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="riviews-section_carosel">
                  <img
                    src={CaroselBackground}
                    alt="CaroselBackground"
                    className="riviews-section_carosel-image"
                  />
                  <div className="riviews-section_rectangle-text slider">
                    <p className="riviews-section_rectangle-description">
                      {reviewsErinDescription}
                    </p>
                  </div>
                </div>
                <div className="riviews-section_people">
                  <Link
                    to="https://www.linkedin.com/in/erinbradford/​"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="riviews-section_people-wrapper">
                      <div className="riviews-section_people-icon">
                        <ThomasEdison className="riviews-section_people-icon-face" />
                      </div>
                    </div>
                  </Link>
                  <p className="riviews-section_people-name">
                    {reviesSliderTwoName}
                  </p>
                  <p className="riviews-section_people-description">
                    {reviewsSliderTwoPosition}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="riviews-section_carosel">
                  <img
                    src={CaroselBackground}
                    alt="CaroselBackground"
                    className="riviews-section_carosel-image"
                  />
                  <div className="riviews-section_rectangle-text slider">
                    <p className="riviews-section_rectangle-description">
                      {reviewsSectionGregDescription}
                    </p>
                  </div>
                </div>
                <div className="riviews-section_people">
                  <Link
                    to="https://www.linkedin.com/in/global-finance-director/​​"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="riviews-section_people-wrapper">
                      <div className="riviews-section_people-icon">
                        <PeterDrucker className="riviews-section_people-icon-face" />
                      </div>
                    </div>
                  </Link>
                  <p className="riviews-section_people-name">
                    {reviewsSliderThreeName}
                  </p>
                  <p className="riviews-section_people-description">
                    {reviewSliderThreePosition}
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewsCoponent;
