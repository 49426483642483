import React from "react";
import ConsistencyIcone from '../../../static/assets/consistency.svg'
import CollaborationIcone from '../../../static/assets/collaboration.svg';
import PlanningIcone from '../../../static/assets/planning.svg';
import ConsensusIcone from '../../../static/assets/consensus.svg';
const BusinessDeveloperBrakePoint = () =>{
    return (
      <div className="business-brake-section">
        <div className="business-brake-section-text">
          <div className="business-brake-section-icon">
            <ConsistencyIcone className="business-brake-section-icon-consistency" />
            <p className="business-brake-section-icon-text">Consistency</p>
          </div>
          <div className="business-brake-section-icon">
            <CollaborationIcone className="business-brake-section-icon-consistency" />
            <p className="business-brake-section-icon-text">Collaboration</p>
          </div>
          <div className="business-brake-section-icon">
            <PlanningIcone className="business-brake-section-icon-consistency" />
            <p className="business-brake-section-icon-text">Planning</p>
          </div>
          <div className="business-brake-section-icon">
            <ConsensusIcone className="business-brake-section-icon-consistency" />
            <p className="business-brake-section-icon-text">Consensus</p>
          </div>
        </div>
      </div>
    );
}
export default BusinessDeveloperBrakePoint