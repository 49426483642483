import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { toast, ToastContainer } from 'react-toastify';
import { useMutation, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import HeigherEducationAdministartionButtonIcon from '../../../static/assets/buttonIcone.svg';
const SUBMIT_CONTACT_DEMO = gql`
  mutation contact($data: ContactUsFormInput!) {
    submitContact(data: $data) {
      errors
    }
  }
`;
const RequestDemoComponent = (props) =>{
  const {
    requestDemoTitle,
    requestDemoDescription,
    requestDemoButton,
    requestDemoImage,
    isBusnessDeveloper,
    learnMoreRef,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const [contact, { loading }] = useMutation(SUBMIT_CONTACT_DEMO);
  const isLoading = loading;
  const onSubmitHandler = values => {
    const {
      username,
      firstName,
      lastName,
      company,
      additionalText,
      phone,
      jobTitle,
      
    } = values;
    const data = {
      email: username,
      firstName,
      lastName,
      company,
      additionalText,
      phone,
      jobTitle,
    };
    contact({ variables: { data } })
      .then(response => {
        console.log(response);
        toast.success('Message sent successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      })
      .catch(errors => {
        console.log(errors);
        toast.error('There was a problem. Please check again later.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };
  return (
    <div className="request-demo-section" ref={learnMoreRef}>
      <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-12">
          <div
            className={`request-demo-section-text ${
              isBusnessDeveloper ? 'businessDeveloper-requestDemo' : ''
            }`}
          >
            <div className="request-demo-section-text-section">
              <div className="request-demo-section-text-introduction">
                <h3 className="request-demo-section-text-title">
                  {requestDemoTitle}
                </h3>
                <p
                  className={`request-demo-section-text-description ${
                    isBusnessDeveloper
                      ? 'businessDeveloper-requestDemo-description'
                      : ''
                  }`}
                >
                  {requestDemoDescription}
                </p>
                <Link
                  className={`request-demo-section-text-button`}
                  to="/case-study"
                >
                  {requestDemoButton}
                  <HeigherEducationAdministartionButtonIcon className="request-demo-section-text-button-icon" />
                </Link>
              </div>
            </div>
            <div className="request-demo-section-image-section">
              <GatsbyImage
                alt={''}
                image={requestDemoImage}
                className="request-demo-section-image"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="request-demo-section-form">
            <p className="request-demo-section-form-title">
              Schedule your demo
            </p>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="request-demo-section-form-input-secton">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  disabled={isLoading}
                  className={`request-demo-section-form-input ${
                    errors.firstName ? 'is-invalid' : ''
                  }`}
                  {...register('firstName', {
                    required: 'Enter your first name!',
                  })}
                />

                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </div>
              <div className="request-demo-section-form-input-secton">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  disabled={isLoading}
                  placeholder="Last name"
                  className={`request-demo-section-form-input ${
                    errors.lastName ? 'is-invalid' : ''
                  }`}
                  {...register('lastName', {
                    required: 'Enter your last name!',
                  })}
                />

                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </div>
              <div className="request-demo-section-form-input-secton">
                <input
                  type="text"
                  id="company"
                  name="company"
                  disabled={isLoading}
                  placeholder="Company"
                  className={`request-demo-section-form-input ${
                    errors.company ? 'is-invalid' : ''
                  }`}
                  {...register('company', {
                    required: 'Enter your company!',
                  })}
                />
                <div className="invalid-feedback">
                  {errors.company?.message}
                </div>
              </div>
              <div className="request-demo-section-form-input-secton">
                <input
                  id="jobTitle"
                  name="jobTitle"
                  className={`request-demo-section-form-input ${
                    errors.jobTitle ? 'is-invalid' : ''
                  }`}
                  placeholder="Job Title"
                  type="text"
                  {...register('jobTitle')}
                />
              </div>
              <div className="request-demo-section-form-input-secton">
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Work E-mail"
                  disabled={isLoading}
                  className={`request-demo-section-form-input ${
                    errors.username ? 'is-invalid' : ''
                  }`}
                  {...register('username', {
                    required: 'Enter your Work E-mail!',
                  })}
                />
                <div className="invalid-feedback">
                  {errors.username?.message}
                </div>
              </div>
              <div className="request-demo-section-form-input-secton">
                <input
                  id="phone"
                  name="phone"
                  className={`request-demo-section-form-input ${
                    errors.phone ? 'is-invalid' : ''
                  }`}
                  placeholder="Phone"
                  type="text"
                  {...register('phone')}
                />
              </div>
              <textarea
                rows={5}
                className="request-demo-section-form-input"
                type="text"
                placeholder="Message"
                id="additionalText"
                name="additionalText"
                {...register('additionalText')}
              />
              <p className="request-demo-section-form-description">
                Agreed needs the information from this form to contact you about
                our products and services. You may unsubscribe from these
                communications at any time. To see how to unsubscribe, and learn
                about our commitment to your data security, take a look at our
                Privacy Policy.
              </p>
              <button
                aria-label="submit"
                className={`request-demo-section-form-button ${
                  isBusnessDeveloper
                    ? 'businessDeveloper-requestDemo-button'
                    : ''
                }`}
              >
                SUBMIT
              </button>
            </form>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}
export default RequestDemoComponent