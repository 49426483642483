import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
const BusinessDeveloperHome = (props) =>{
    const{businessTitle,businessDeveloperImage} = props;
    return (
      <div className="business-home-section">
        <div className="full-container">
          <h1 className="business-home-section-title">{businessTitle}</h1>
          <GatsbyImage
            image={businessDeveloperImage}
            className="business-home-section-image"
            alt={''}
          />
        </div>
      </div>
    );
}
export default BusinessDeveloperHome